.container {
    position: relative;
    padding: 0 20px;
    margin-top: 80px;
  }
  


.paperContainer {
    padding: 20px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.error {
    color: red;
    font-size: 2em;
}

.title {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.subtitle {
    font-size: 1.2em;
    margin-bottom: 1em;
    font-weight: normal;
}

.body {
    font-size: 1em;
}

.paperContainer {
    position: relative;
  }
  
  .deleteButtonContainer {
    position: absolute;
    top: 10px; 
    right: 10px; 
  }
  
  .deleteButton {
    background-color: #C00020;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.75rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    font-weight: 500;
    border-radius: 5px; /* Rounded corners */
    transition: all 0.2s ease; /* Smooth transition for hover effect */
  }
  
  .goBackButton {
    position: relative;
    margin: 0 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #eaeaea;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
  
  .goBackButton:hover {
    background-color: #ccc;
  }
  
  