/* Style the calendar itself */
.rbc-calendar {
    /* Add a border radius */
    border-radius: 5px;
    /* Add a box shadow */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  /* Remove the border around the time view */
  .rbc-time-view {
    border: none;
  }
  
  /* Style the header of the calendar */
  .rbc-header {
    /* Remove the border */
    border: none;
    /* Make the text bold */
    font-weight: bold;
  }
  
  /* Remove the border around the time header */
  .rbc-time-header > .rbc-row {
    border: none;
  }
  
  /* Style the content of the time view */
  .rbc-time-content {
    /* Add a top border */
    border-top: 1px solid #e0e0e0;
    /* Remove the left and right borders */
    border-left: none;
    border-right: none;
    
  }
  
  .selected-view-btn {
    /* Set the border */
    border: 2px solid green;
    /* Set the text color */
    color: green;
    /* Set the background color to transparent */
    background-color: transparent;
  }
  
  .selected-view-btn:hover {
    /* Adjust the border color on hover */
    border-color: green;
    /* Set the background color */
    background-color: green;
    /* Set the text color on hover */
    color: white;
  }
  
/* +x more */
.rbc-show-more {
    color: #3173AD;
    padding-left: 10px;
    padding-top: 3px;
    background-color: rgba(0, 0, 0, 0.0);
}

.rbc-event-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-event-label {
  display: none;
}

.custom-button {
  background-color: #6377FF;
  color: white;
  margin-right: 5px;
}

.custom-button:hover {
  background-color: #265A89;
}

.rbc-off-range .rbc-event {
  background-color: black;
  }