@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');



html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
  overscroll-behavior: none;
  -webkit-tap-highlight-color: transparent;



  /* background-color: #FAFAFA; This is a light gray color */

}